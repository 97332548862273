import axios from 'axios'
export default {
  methods: {
    rec_busquedas_tipos (pTipo, pDato) {
      const urlroute = this.$store.getters.getUrl + 'busquedas/' + pTipo + '/' + pDato
      this.loadingBuscar = true
      this.item_registros = []
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.item_registros = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingBuscar = false
      })
    }

  }
}
